import { navigate } from 'gatsby';
import React, {  useEffect, useRef, useState } from 'react';

function UploadPhoto() {

  const fileInput = useRef();
  const [url, setUrl] = useState( '' );
  const [myPhoto, setImage] = useState( '' );

  useEffect( () => {
    const origin = window.location.host;

    if( origin.includes( 'com' ) )
    {
      navigate( '/' );
    }
  }, [] );

  const onImageChange = ( event ) => {
    if ( event.target.files && event.target.files[0] ) {
      setImage( URL.createObjectURL( event.target.files[0] ) );
    }
  };

  function submit(){
    let file = fileInput.current.files[0];
    let formData = new FormData();
    formData.append(
      'image', file, file.name
    );

    fetch( 'https://app.getmybubble.co/api/upload', {
      method: 'POST',
      body: formData
    } ).then( res => res.json() )
      .then( ( { url } ) => setUrl( url ) )
      .catch( console );
  }

  return(
    <>
      <form method="POST" encType="multipart/form-data">
        <div className="field">
          <label htmlFor="image">Image Upload</label>

          <input  onChange={onImageChange} type="file"  accept=".png, .jpg, .jpeg, .svg"  ref={fileInput} name="image" id="image"/>
        </div>

        <button type="button" onClick={submit}> Submit</button>
      </form>

      <div className='row'></div>

      {
        url &&
        <> Uploaded to {url}
        </>
      }

      <div className='mt-2'></div>
        Preview:

      <div className='row'></div>

      {myPhoto &&
            <img src={myPhoto} style={{ maxHeight: '800px', maxWidth: '600px' }} alt="preview image" />
      }

      <div className='mt-2'></div>

    </>
  );
}

export default UploadPhoto;
